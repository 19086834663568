import React from "react";
import styles from "./contact-box.module.scss";
import TextInput from "../../atoms/text-input/text-input";
import TextArea from "../../atoms/textarea/textarea";
import { useState } from "react";
import emailjs from "emailjs-com"
import { useNavigate  } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactBox = () => {
let navigate = useNavigate ();

const [show, setShow] = useState(false);
const [formError, setFormError] = useState(null);
const [formValues, setFormValues] = useState({
	name: "",
	email: "",
	subject: "",
	message: ""
});

const handleInputChange = (e) => {
	setFormValues({
		 ...formValues, 
		 [e.target.name]: e.target.value });
};

const sendEmail = (e) => {
    emailjs.sendForm('service_8i2rn1b', 'template_rqjzlff', e.target, 'mowrgNa2iNmeQ4WZm')
    .then((result) => {
        console.log(result.text);
		setShow(true);
		toast('Wiadomość wysłana!', {
			autoClose: 2000,
			position: 'top-center',
			className: styles.toastBackground,	
			onClose: () => {
			  setShow(false);
			  setTimeout(() => {
				  navigate("/#home")
			  }, 2000);
			},
		  });
    }, (error) => {
        console.log(error.text);
    });
};

const validate = (formValues) => {
	if(!formValues.name) {
		return "Imie i nazwisko jest wymagane!"
	}else if (formValues.name.length < 2){
		return "Imie i nazwisko jest wymagane!"
	}

	if(!formValues.email) {
		return "Email jest wymagany!"
	}else if (!/^[^\s@]+@[^\s@]+.[^\s@]{2,}$/i.test(formValues.email)){
		return "Email jest niepoprawny!"
	}

	if(!formValues.subject) {
		return "Temat jest wymagany!"
	}else if (formValues.subject.length < 2){
		return "Temat jest wymagany!"
	}

	if(!formValues.message) {
		return "Treść jest wymagana!"
	}else if (formValues.message.length < 2){
		return "Treść jest wymagana!"
	}

	return null;
};

  
const handleSubmit = (e) => {
	e.preventDefault();
	const errorMsg = validate(formValues)
	if(errorMsg) {
		setFormError(errorMsg)
		console.log(errorMsg)
		return
	}else {
		setFormError("")
	}
	sendEmail(e)
}
    return ( 
    	<div className={styles.contactBox} > 
		<ToastContainer />
		<form className={styles.contactBoxForm} onSubmit={handleSubmit}>
			<label className={styles.textErrorLabel} >{formError}</label>
			<TextInput label="Imie i nazwisko" id="name" name="name" type="text" value={formValues.name} onChange={handleInputChange}/>
			<TextInput label="Email" id="email" name="email" type="text" error={formError} value={formValues.email} onChange={handleInputChange}/>
			<TextInput label="Temat" id="subject" name="subject" type="text" error={formError} value={formValues.subject} onChange={handleInputChange}/>
			<TextArea label="Opisz swój problem" id="message" name="message" value={formValues.message} error={formError} type="textarea"  cols="40" rows="5" onChange={handleInputChange}/>
			<button className={styles.contactBoxButton} type="submit">Wyślij</button>
			</form>
		</div>
    )
} 
 
export default ContactBox;