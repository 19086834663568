import React from "react";
import styles from "./offer-box.module.scss"
import { Link } from "react-router-dom";
 
const OfferBox = (props) => {
	const { id, name, description } = props;
    return (
    	<div className={styles.offerBox}>
			<div className={styles.offerBoxTitle}><h1 className={styles.offerBoxTitleText}>{name}</h1></div>
			<div className={styles.offerBoxText}>{description}</div>
			<Link to={`/offers/: ${id}`} state={{stateParam: props}} className={styles.offerBoxButton}>Poznaj ofertę</Link>
		</div>
    )
} 
 
 
export default OfferBox;