import React from "react";
import PropTypes from "prop-types"
import styles from "./text-input.module.scss"

const TextInput = ({onChange, label, name, id, type, error, ...props}) => {
	return (
		<div className={styles.textInputDiv}>
			<label className={styles.textInputLabel} htmlFor={id}>{label}</label>
			<input className={styles.textInput} onChange={onChange} name={name} id={id} type={type}></input>
		</div>
	);
};

TextInput.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	type: PropTypes.string,
}

export default TextInput;