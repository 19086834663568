import React from "react";
import styles from "./page404.module.scss"
import { Link } from "react-router-dom";
 
const Page404 = () => {
    return (
        <div className={styles.page404}>
            <h1 className={styles.page404Title}>Błąd</h1>
            <h1 className={styles.page404SubTitle}>Taka strona nie istnieje</h1>
            <Link to="/" className={styles.page404Button}>Powrót</Link>
        </div>
    )
} 

export default Page404;