import React from "react";
import styles from "./contact.module.scss"
import ContactBox from "./../../components/contact-box/contact-box"

const contactType = [
    {
        name: "Telefon",
        id: "phone",
        description: "698-162-977"
    },
    {
        name: "E-mail",
        id: "email",
        description: "jgbiuro@outlook.com",
    },
    {
        name: "Adres",
        id: "adress",
        description: "Opoczno ul.Józefa Piłsudskiego 25",
    },
    {
        name: "NIP",
        id: "nip",
        description: "7681676304"
    },
    {
        name: "Regon",
        id: "regon",
        description: "521589334"
    },
    {
        name: "JGBiuro",
        id: "company",
        description: ""
    },
    
]
const Contact = () => {
    return (
        <div className={styles.contactPage} id="contact">
            <div className={styles.contactInfo}>
                <h1 className={styles.contactTitle}> z nami</h1>
            <div className={styles.contacInfoGrid}>
            {contactType.map(contact =>(
                <div className={styles.contacInfoPanel}>
                    <div className={styles.contactInfoImg} data-type={contact.id}></div>
                    <div className={styles.contactInfoName} >{contact.name}</div>
                    <div className={styles.contactInfoDescription} >{contact.description}</div>
                </div>
            ))}
            </div>
        </div>
        <ContactBox/>
        </div>
    )
} 
 
 
export default Contact;

