import React from "react";
import styles from "./footer.module.scss";
 
const Footer = () => {
    const [facebookLink, instagramLink] = ["https://www.facebook.com/profile.php?id=100083224786322", "https://www.instagram.com/biurorachunkowejustyna/"];
    const [mailContact, phoneContact] = ["jgbiuro@outlook.com", "698-162-977"];
    return (
        <footer className={styles.footer}>
            <div className={styles.footerLogoDiv}>
                <div className={styles.footerLogo}></div>
            </div>
            <div className={styles.footerInfo}>
                <div className={styles.footerAdressInfo}>
                    <h1>Dane adresowe</h1>
                    <div className={styles.footerAdressText}>Piłsudskiego 25 26-300 Opoczno, Poland</div>
                </div>
                <div className={styles.footerAdressInfo}>
                    <h1>Godziny otwarcia</h1>
                    <div className={styles.footerAdressText}>pon - pt: 8:00 - 16:00</div>
                </div>
                <div className={styles.footerAdressInfo}>
                    <h1>Kontakt</h1>
                    <h2 data-type="Phone">{phoneContact}</h2>
                    <h2 data-type="Mail">{mailContact}</h2>
                </div>
                <div className={styles.footerAdressInfo}>
                    <h1>Sociale</h1>
                    <a data-type="Facebook" href={facebookLink} rel="noreferrer" target="_blank" >Facebook</a>
                    <a data-type="Instagram" href={instagramLink} rel="noreferrer" target="_blank">Instagram</a>
                </div>
            </div>
        </footer>
    )
} 
 
 
export default Footer;