import React from "react";
import styles from "../offers/offers.module.scss"
import {useLocation } from "react-router-dom";

const OffersPage = () => {
    const location = useLocation()
    const propsInfo = location.state.stateParam
    console.log(propsInfo)
    return (
        <div className={styles.offersPage}>
        <div className={styles.offersPageWrapper}>
            <h1 className={styles.offersPageTitle}>{propsInfo.name}</h1>
            <ul className={styles.offersPageText}>
                {propsInfo.long.map(offer =>(
                    <li>{offer}</li>
                ))}
            </ul>
            </div>
            <div className={styles.offersPageImage}></div>
    </div>
    )
} 
 
 
export default OffersPage;