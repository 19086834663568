import React from "react";
import styles from "./home.module.scss"
import {HashLink} from "react-router-hash-link"

const Home = () => {
    return (
        <div className={styles.homePage} id="home">
            <div className={styles.homePageWrapper}>
                <h1 className={styles.homepageTitle}>Nowoczesna</h1>
                <h2 className={styles.homepagesubTitle}>ksiegowość</h2>
                <div className={styles.homepageButtonDiv}><HashLink smooth to="/#about">O nas</HashLink></div>
            </div>
            <div className={styles.homepageImage}></div>
        </div>
    )
} 
 
 
export default Home;