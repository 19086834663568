import React from "react";
import PropTypes from "prop-types"
import styles from "./textarea.module.scss"

const TextArea = ({onChange, label, name, id, type, error, ...props}) => {
	return (
		<div className={styles.textAreaDiv}>
			<label className={styles.textAreaLabel} htmlFor={id}>{label}</label>
			<textarea className={styles.textArea} onChange={onChange} name={name} id={id} type={type}></textarea>
		</div>
	);
};

TextArea.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	type: PropTypes.string,
}

export default TextArea;