import React from "react";
import "./navbar.css";
import {useState} from "react"
import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link"


const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	
	return (
	  <div className="Navbar">
		<HashLink smooth to="/#home" className="nav-logo"></HashLink>
		<div className={`nav-items ${isOpen && "open"}`}>
			<HashLink smooth to="/#home">Home</HashLink>
			<HashLink smooth to="/#about">O nas</HashLink>
			<HashLink smooth to="/#offer">Oferta</HashLink>
			<Link to="/contact" smooth={true} duration={50} offset={-300} spy={true} exact="true" >Kontakt</Link>	
		</div>
		<div
		  className={`nav-toggle ${isOpen && "open"}`}
		  onClick={() => setIsOpen(!isOpen)}
		>
		  <div className="bar"></div>
		</div>
	  </div>
	);
  };

export default Navbar;

