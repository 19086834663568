import React from "react";
import "./main.scss";
import Home from "./../home/home"
import About from "./../about/about"
import Offer from "./../offer/offer"

function Main() {
	return (
		<>
		<div className="main-container">
			<Home/>
			<About/>
			<Offer/>
		</div>
		</>
	);
};

export default Main;
