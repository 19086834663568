import React from "react";
import './assets/styles.css'
import "./app.scss";
import Navbar from "./components/navbar/navbar"
import Footer from "./components/footer/footer"
import Main from "./views/main/main"
import { Route, Routes} from "react-router-dom"
import OffersPage from "./views/offer/offers/offers"
import Page404 from "./views/page404/page404";
import Contact from "./views/contact/contact"
import ScrollToTop from "./components/scrolltotop/scrolltotop";

function App() {
	return (
		<>
			<Navbar/>
			<ScrollToTop/>
			<Routes>
				<Route path="/" element={<Main/>}></Route>
				<Route path="/offers/:id" element={<OffersPage/>}></Route>
				<Route path="/contact" element={<Contact/>}></Route>
				<Route path="*" element={<Page404/>}></Route>
			</Routes>
			<Footer />
		</>
	);
};

export default App;
