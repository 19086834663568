import React from "react";
import styles from "./about.module.scss"
import {HashLink} from "react-router-hash-link"

const About = () => {
    return (  
        <div className={styles.aboutPage} id="about">
            <div className={styles.aboutPageWrapper}>
                <h1 className={styles.aboutPageTitle}>Kim</h1>
                <div className={styles.aboutPageText}>
                Właścicielem firmy jest Justyna Grzybek - absolwentka Wydziału Rachunkowości Uniwersytetu Ekonomicznego w Krakowie.
                Dzięki posiadanemu doświadczeniu oraz wiedzy zdobytej między innymi w Stowarzyszeniu Księgowych w Polsce oraz jako wieloletni pracownik Urzędu Skarbowego, oferuję usługi na najwyższym poziomie.
                Jako biuro rachunkowe dobieramy rozwiązania usprawniające dokumentację. Zajmujemy się prowadzeniem księgowości dla jednoosobowych działalności gospodarczych, spółek prawa handlowego oraz fundacji i stowarzyszeń na terenie województwa łódzkiego.
                </div>
                <div className={styles.aboutPageButtonDiv}><HashLink smooth to="/#offer">Poznaj ofertę</HashLink></div>
            </div>
            <div className={styles.aboutPageImage}></div>
        </div>
    )
} 
 
 
export default About;

