import React from "react";
import styles from "./offer.module.scss"
import OfferBox from "./../../components/offer-box/offer-box"

const offerList = [
    {
        name: "Obsługa księgowa",
        description: "Zapewnia pełen obraz sytuacji finansowej przedsiębiorstwa. Pełna ewidencja przychódów, kosztów oraz sprawozdania finansowe.",
        long: ["Zakres usług biura to między innymi:", "- Prowadzenie ksiąg handlowych", "- Prowadzenie podatkowej księgi przychodów i rozchodów (PKPiR)", "- Prowadzenie ryczałtu ewidencjonowanego oraz karty podatkowej", "- Sporządzanie deklaracji podatkowych m.in. CIT, PIT, VAT oraz pozostałych obligatoryjnych wymaganych deklaracji podatkowych", "- Sporządzanie deklaracji INTRASTAT, UE oraz dokonywanie rozliczeń związanych z obrotem WNT i WDT", "- Prowadzenie ewidencji środków trwałych, wartości niematerialnych i prawnych oraz wyposażenia", "- Rozliczanie delegacji krajowych i zagranicznych w tym sporządzenie ewidencji przebiegu pojazdu", "- Sporządzanie sprawozdań do GUS Sporządzanie sprawozdań finansowych (bilans, rachunek zysków i strat, rachunek przepływów pieniężnych, zestawianie zmian na kapitale własnym)", "- Monitorowanie i stała analiza kosztów i przychodów Monitorowanie zobowiązań i należności (potwierdzanie sald)", "- Obsluga ksiegowa opis krotki Podmioty prowadzące działalność gospodarczą mają różne potrzeby, w których pomoc biura rachunkowego jest niezbędna, dlatego każdego klienta traktujemy indywidualnie. Zapewniamy możliwość wysłania skanowanych dokumentów e-mailem."] ,
        id: "obslugaksiegowa"
    },
    {
        name: "Obsługa kadrowa",
        description: "Obsługa kadrowo płacowa zapewnia pełną dokumentację, umowy z pracownikami, roczne deklaracje",
        long: ["Oferujemy prowadzenie usług kadrowo- płacowych, do ktorych zaliczamy", "- Prowadzenie teczki akt osobowych", "- Przygotowywanie dokumentacji do zawarcia stosunku pracy, zmiany warunków zatrudnienia i wypowiedzeń", "- Naliczanie wymiaru i rozliczanie wykorzystanych urlopów", "- Rozliczanie ewidencji czasu pracy", "- Prowadzenie dokumentacji związanej m.in. z nieobecnościami pracowników, zajęciami komorniczymi, świadczeniami pracowniczymi", "- Naliczanie wynagrodzeń i sporządzanie list płac", "- Naliczanie podatku dochodowego od osób fizycznych od uzyskanych przychodów", "- Naliczanie należnych składek na ubezpieczenia: emerytalne, rentowe, chorobowe, wypadkowe, zdrowotne, Fundusz Pracy, Fundusz Gwarantowanych Świadczeń Pracowniczych Rejestrację i wyrejestrowywanie pracowników w ZUS", "- Obsługa rozliczeń PFRON oraz składanie deklaracji e-PFRON", "- Sporządzanie deklaracji podatkowych PIT-11, PIT 40, PIT 8R, PIT 8AR, PIT 8C", "- Sporządzanie sprawozdań GUS", "- Sporządzanie deklaracji rozliczeniowych i raportów imiennych zgodnie z obowiązującymi przepisami i przesyłanie do ZUS w formie elektronicznego przekazu", "- Sporządzanie deklaracji środowiskowych."],
        id: "kadrowa"
    },
    {
        name: "Pozyskiwanie środków krajowych",
        description: "Pozyskiwanie środków krajowych" ,
        long: ["- Przygotowanie wniosku zgodnie z pomysłem klienta", "- Przygotowywanie i prowadzenie dokumentacji koniecznej do zrefundowania kosztów w ramach prowadzonych projektów", "- Przygotowywanie okresowych, wskazanych we wnioskach, sprawozdań, wniosków o płatność pośrednią oraz rozliczeń końcowych", "- Reprezentacja podczas kontroli projektów finansowanych z funduszy europejskich", "- Controlling na potrzeby bieżącego ustalania planu wydatków"],
        id: "pracazdoradca"
    },    
]

const Offer = () => {
    return (
        <div className={styles.offerPage} id="offer">
            <h1 className={styles.offerpageTitle}> oferta</h1>
            <div className={styles.offerGrid}>
            {offerList.map(offer =>(
                <OfferBox key={offer.id} id={offer.id} name={offer.name} description={offer.description} long={offer.long} />
            ))}
            </div>
        </div>
    )
} 

export default Offer;